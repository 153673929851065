class Tracking {

	track_submit_success(type, unit, segment, userType) {

		try {
			gtag(
				'event',
				'Exibição',
				{
					'event_category': type === 'schedule' && 'Maple Bear Bsb - Agendamento',
					'event_label': 'Lead cadastrado com sucesso',
					'event_unit': unit,
					'event_segment': segment,
					'event_usertype': userType
				}
			);
		} catch(e) {
			// Do nothing
		}
	}

	track_submit_error(type, unit, segment, userType) {

		try {
			gtag(
				'event',
				'Exibição',
				{
					'event_category': type === 'schedule' && 'Maple Bear Bsb - Agendamento',
					'event_label': 'Erro de cadastro',
					'event_unit': unit,
					'event_segment': segment,
					'event_usertype': userType
				}
			);
		} catch(e) {
			// Do nothing
		}
	}

	track_submit_duplicate(type, unit, segment, userType) {

		try {
			gtag(
				'event',
				'Exibição',
				{
					'event_category': type === 'schedule' && 'Maple Bear Bsb - Agendamento',
					'event_label': 'Lead já cadastrado',
					'event_unit': unit,
					'event_segment': segment,
					'event_usertype': userType
				}
			);
		} catch(e) {
			// Do nothing
		}
	}
}
export default new Tracking();