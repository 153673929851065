$(document).ready(function () {
    
    let iconPlus = $(".contact-icon .icon-plus");
    let contactBtn = $('.contact-icon')
    let contactFeatures = $('.features')

    contactBtn.on('click', ()=>{
        if(contactFeatures.hasClass('open')){
            $('.features').fadeOut(400, 'swing', function(){
                $('.features').removeClass('open')
                iconPlus.animate({ rotate: "0deg" }, 300);
            })
        }
        else{
            $(".features").fadeIn(400, 'swing', function(){
                $('.features').addClass('open')
                iconPlus.animate({ rotate: "45deg" }, 300);
            })

        }
    })

    iconPlus.on('click', () => {
        if (contactFeatures.hasClass('open')) {
            contactFeatures.fadeOut(400, 'swing', function() {
                contactFeatures.removeClass('open')
                iconPlus.animate({ rotate: "0deg" }, 300);
            })
        }
    })
})


