const selectUnits = jQuery('select[name="unit"]');
selectUnits.each(function() {

	jQuery(this).selectmenu({
		appendTo: jQuery(this).parent().get(0),
		create: function( event, ui ) {
			const height = Math.min(jQuery(this).find('option').length * 38 + 90, 250);
			jQuery(this).parent().get(0).style.setProperty('--menu-height', height+'px');
		},
		open: function( event, ui ) {
			const height = Math.min(jQuery(this).find('option').length * 38 + 90, 250);
			jQuery(this).parent().get(0).style.setProperty('--menu-height', height+'px');
		},
		change: function( event, ui ) {
			if(jQuery(this).find('option[disabled]').length) {
				jQuery(this).find('option[disabled]').remove()
				jQuery(this).selectmenu('refresh')
			}
			jQuery(this).parent().addClass('filled')
			jQuery(this).valid()
		}
	});
});

const selectSegments = jQuery('select[name="segment"]');
selectSegments.each(function() {

	jQuery(this).selectmenu({
		appendTo: jQuery(this).parent().get(0),
		create: function( event, ui ) {
			const height = Math.min(jQuery(this).find('option').length * 38 + 90, 250);
			jQuery(this).parent().get(0).style.setProperty('--menu-height', height+'px');
		},
		open: function( event, ui ) {
			const height = Math.min(jQuery(this).find('option').length * 38 + 90, 250);
			jQuery(this).parent().get(0).style.setProperty('--menu-height', height+'px');
		},
		change: function( event, ui ) {
			if(jQuery(this).find('option[disabled]').length) {
				jQuery(this).find('option[disabled]').remove()
				jQuery(this).selectmenu('refresh')
			}
			jQuery(this).parent().addClass('filled')
			jQuery(this).valid()
		}
	});
});

const selectAcks = jQuery('select[name="acknowledgment"]');
selectAcks.each(function() {

	jQuery(this).selectmenu({
		appendTo: jQuery(this).parent().get(0),
		create: function( event, ui ) {
			const height = Math.min(jQuery(this).find('option').length * 38 + 90, 250);
			jQuery(this).parent().get(0).style.setProperty('--menu-height', height+'px');
		},
		open: function( event, ui ) {
			const height = Math.min(jQuery(this).find('option').length * 38 + 90, 250);
			jQuery(this).parent().get(0).style.setProperty('--menu-height', height+'px');
		},
		change: function( event, ui ) {
			if(jQuery(this).find('option[disabled]').length) {
				jQuery(this).find('option[disabled]').remove()
				jQuery(this).selectmenu('refresh')
			}
			jQuery(this).parent().addClass('filled')
			jQuery(this).valid()
		}
	});
});

document.addEventListener('sebDocumentReady', function() {

	const observer = new MutationObserver((mutations) => {
	    mutations.forEach((mutation) => {
	        const { target } = mutation;
	        if (mutation.attributeName === 'class') {
	            if(mutation.target.classList.contains('error') && !jQuery(target).parent().hasClass('checkbox-container')) {
	            	jQuery(target).parent().addClass('error')
	            } else {
	            	jQuery(target).parent().removeClass('error')
	            }
	        }
	    });
	});
	jQuery('.selectWrapper > select, .form-control > input[type="text"], .form-control > input[type="email"], .form-control textarea, input[type="checkbox"], .form-control > select').each(function() {
		observer.observe(this, {
			attributes : true
		})
	})
})