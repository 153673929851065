import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";

document.addEventListener('sebDocumentReady', function() {

	const testimony = jQuery('.testimony');	
 	testimony.each(function(index) {

		const current = jQuery(this);
		const carousel = current.find('.carousel')[0];
        const btnNext = current.find('.btn-right');
		const btnPrev = current.find('.btn-left');
		const readMore = current.find('.read-more');
		const readLess = current.find('.read-less');
		const testimonyText = current.find('.testimony-quote');

		var slider = tns({
			
			container: carousel,
			items: 1.2,
			controls: false,
			mouseDrag: true,
			touch: true,
			loop: true,
			nav: false,
			lazyload: true, 
			arrowKeys: true,
            responsive: {
				940: {
			        items: 1,
				}
			}
		});	

        btnNext.on('click', function() {

		    slider.goTo('next');
		});

		btnPrev.on('click', function() {

		    slider.goTo('prev');
		});

		function loadLimitText() {
			if(jQuery(document).width() <= 940) {
				testimonyText.each(function() {	
					if(this.innerText.length > 500) {
						jQuery(this).addClass('show-read-more limit-quote')
					}
				})
			} else {
				testimonyText.each(function() {	
					jQuery(this).removeClass('show-read-more limit-quote')
				})
			}
		}

		loadLimitText();

		window.addEventListener('resize', function() {
			loadLimitText()
		})


		readMore.on('click', function() {
			jQuery(this).prev('.show-read-more').removeClass('show-read-more limit-quote');
		    jQuery(this).nextAll('.read-less').css("display", "block")
		});

		readLess.on('click', function() {
			jQuery(this).css('display', 'none');
		    jQuery(this).prevAll('.testimony-quote').addClass('show-read-more limit-quote');
		});
	});	
});