import Utils from './utils.js';

document.addEventListener('sebDocumentReady', function() {

	const modalMedia = jQuery('#modal-media');
	const modalIframe = modalMedia.find('iframe');
	const modalImage = modalMedia.find('> img');
	const modalArrows = modalMedia.find('.btn-icon');
	let currentGallery = null;
	let currentMedia = null;

	const handleArrowClick = (event) => {
		if(event.code === 'ArrowLeft') {
			goToPrevious();
		} else if(event.code === 'ArrowRight') {
			goToNext();
		}
	}

	modalMedia.find('.btn-icon.btn-previous').on('click', () => {goToPrevious()});
	modalMedia.find('.btn-icon.btn-next').on('click', () => {goToNext()});

	const closeModal = () => {

		Utils.enableScroll();
		modalIframe.each(function() {
			const content = this.contentWindow;
			content.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
		});
		modalIframe.hide();
        modalIframe.attr('src', '');
        modalImage.hide();
        modalImage.attr('src', '');
        currentGallery = null;
        currentMedia = null;
        modalArrows.hide();
        document.removeEventListener('keyup', handleArrowClick);
        modalMedia.hide();
	}

	const btnCloseModal = modalMedia.find('.btn-close-modal');
	btnCloseModal.on('click', closeModal);

	const modalBackground = modalMedia.find('.background');
	modalBackground.on('click', closeModal);
	
	this.addEventListener('keyup', function(event) {

		if(event.code == 'Escape') {

			if(modalMedia.is(":visible")) {

				closeModal();
			}
		}
	});

	const buttonsVideo = jQuery('.btn-open-video').not('.grid-gallery .btn-open-video');	
	buttonsVideo.each(function(index) {

		const currentButton = jQuery(this);
		currentButton.on('click', function() {

			const videoUrl = jQuery(this).data('video-url');
			if(videoUrl) {

				Utils.disableScroll();
				modalImage.hide();
				modalIframe.show();
				modalIframe.attr('src', videoUrl);
				modalMedia.show();
				modalIframe.each(function() {
					const content = this.contentWindow;
					content.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
				});
			}
		});
	});

	const buttonsGallery = jQuery('.grid-gallery .item');	
	buttonsGallery.each(function(index) {

		const currentButton = jQuery(this);
		currentButton.on('click', function() {
			const item = jQuery(this);
			currentMedia = item.data('index');
			currentGallery = item.parent().parent();
			modalArrows.show();

			const description = item.find('.legend').text();
			if(item.data('type') === 'video') {
				
				modalIframe.show();
				const videoUrl = item.find('.btn-open-video').data('video-url');
				if(videoUrl) {

					Utils.disableScroll();
					modalIframe.attr('src', videoUrl);
					modalIframe.attr('title', description);
					modalMedia.show();
					document.addEventListener('keyup', handleArrowClick);
				}
			} else {

				modalImage.show();
				const imageUrl = item.find('img').attr('src');
				if(imageUrl) {

					Utils.disableScroll();
					modalImage.attr('src', imageUrl);
					modalImage.attr('title', description);
					modalMedia.show();
					document.addEventListener('keyup', handleArrowClick);
				}
			}
		});
	});

	const goToPrevious = () => {
		if(currentGallery && currentMedia) {
			const items = currentGallery.find('.item');
			currentMedia = currentMedia != 1 ? currentMedia - 1 : items.length;
			updateCurrentMedia();
		}
	}
	const goToNext = () => {
		if(currentGallery && currentMedia) {
			const items = currentGallery.find('.item');
			currentMedia = currentMedia != items.length ? currentMedia + 1 : 1;
			updateCurrentMedia();
		}
	}

	const updateCurrentMedia = () => {
		const item = jQuery(currentGallery.find('.item')[currentMedia - 1]);
		const description = item.find('.legend').text();
		if(item.data('type') === 'video') {
			modalImage.hide();
			const videoUrl = item.find('.btn-open-video').data('video-url');
			if(videoUrl) {
				modalImage.hide();
				modalIframe.show();
				modalIframe.attr('src', videoUrl);
				modalIframe.attr('title', description);
				modalMedia.show();
			}
		} else {
			
			const imageUrl = item.find('img').attr('src');
			if(imageUrl) {
				modalIframe.hide();
				modalImage.show();
				modalImage.attr('src', imageUrl);
				modalImage.attr('title', description);
				modalMedia.show();
			}
		}
	};
})