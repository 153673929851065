import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";

document.addEventListener('sebDocumentReady', function() {

	const animatedNumbers = jQuery('.animated-numbers');
	animatedNumbers.each(function(index) {

		
		const currentSection = jQuery(this);
		const carousel = currentSection.find('.carousel');
		const cards = carousel.find('.item');
		const numbersBox = cards.find('.number');

		const STEPS = 25;
		let animationStep = 0;

		function animateScroll() {

			if(animationStep >= STEPS) {
				return;
			}

			numbersBox.each(function() {
				const currentBox = jQuery(this);
				const total = currentBox.data('value');
				const value = Math.round(total / STEPS) * (animationStep+1);
				let number = (value >= 1000) ? ("+"+String(Math.round(value / 1000)))+"Mil" : ("+"+String(value));
				currentBox.html('<p>'+ number +'</p>');
			});

			//const awaiting = ((-1/(Math.log(animationStep/STEPS))) + 1)* 50;
			const awaiting = 70;
			animationStep++;

			setTimeout(() => {
				requestAnimationFrame(animateScroll);
			}, awaiting);
		}

		
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				
				if(entry.intersectionRatio > 0.5) {
					setTimeout(() => {
						animateScroll();
					}, 400);
					observer.unobserve(entry.target);
				}
			});

		}, {threshold: 0.5});

		observer.observe(currentSection[0]);
		var slider = tns({
			
			container: carousel[0],
			mouseDrag: true,
			controls: false,
			nav: false,
			loop: false,
			touch: true,
			items: 1,
			responsive: {

				941: {

					items: 3,
				}
			}
		});

		const btnPrevious = currentSection.find('.box-carousel .btn-previous');
		const btnNext = currentSection.find('.box-carousel .btn-next');

		if(cards.length > 1) {

			btnPrevious.on('click', function() {
				slider.goTo('prev');
			});

			btnNext.on('click', function() {
				slider.goTo('next');
			});

			slider.events.on('indexChanged', function(e) {

				btnPrevious.removeClass('blocked');	
				btnNext.removeClass('blocked');
				
				if(e.index == 0) {

					btnPrevious.addClass('blocked');
				}

				if(e.index == (slider.getInfo().slideCountNew - (window.innerWidth > 940 ?  3 : 1))) {

					btnNext.addClass('blocked');
				}
			});

		} else {

			btnPrevious.hide();
			btnNext.hide();
		}

		function resizeCards() {

			const cards = currentSection.find('.tns-item');

			if(cards.length > 1) {

				if(window.innerWidth > 940 && cards.length < 4) {
					btnPrevious.hide();
					btnNext.hide();

				} else {

					btnNext.show();
					btnPrevious.show();
				}

				cards.css('height', 'initial')

				setTimeout(function() {

					var maxHeight = 0;
					cards.each(function() {
						maxHeight = Math.max(maxHeight, jQuery(this).outerHeight())
					})

					cards.each(function() {
						jQuery(this).css('height', maxHeight+'px')
					})

				}, 100);
			}
		}

		window.addEventListener('resize', function(event) {

		    resizeCards();
		});

		resizeCards();
	});
});