class Utils {

	loadSubjects(callback) {

		const site_id = UNIT_INFO.site_id;
		fetch('/api/subjects/?site_id='+site_id)
		.then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadUnits(callback) {

		fetch('/dynamics/units/pueri')
		.then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadAcknowledgments(callback) {

		fetch('/dynamics/find_out/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadCalendar(unit_id, callback) {

		fetch('/dynamics/calendar/' + unit_id + '/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	loadSegments(unit_id, callback) {

		fetch('/dynamics/courses/'+unit_id+'/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			callback(response)
		})
	}

	sanitizeText(text) {

		return text.replace(/(<([^>]+)>)/ig,"");
	}

	isElementInViewport(el) {

		var rect = el.getBoundingClientRect();

	  	return (
		    rect.top >= 0 &&
		    rect.left >= 0 &&
		    rect.bottom <= (window.innerHeight || document. documentElement.clientHeight) &&
		    rect.right <= (window.innerWidth || document. documentElement.clientWidth)
	  	);
	}

	scrollToStart() {
 
	    jQuery("html, body").animate({ scrollTop: "0" });
	}

	scrollToElement(el, position = 'center') {

		const paddingTop = 180;

		switch(position) {

			case 'start':

				const y = el.getBoundingClientRect().top + window.pageYOffset - paddingTop;

				window.scrollTo({top: y, behavior: 'smooth'});

				break;

			case 'center':

				el.scrollIntoView({behavior: 'smooth', block:  position });
				break;

			default:
				break;
		}
	}

	formatPhone(value) {
			
		if(value.length == 11) {
			return value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
		} 

		if(value.length == 10) {
			return value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
		}

		return value;		
	}

	truncateString(str, num) {

  		if (str.length <= num) {
		    return str;
		}

  		return str.slice(0, num) + '...';
	}

	formatTwoDigits(number) {

		return String('0' + number).slice(-2);
	}

	monthEN(index) {
		const months = ["January", "February", "March", "April",
				"May", "June", "July", "August", "September",
				"October", "November", "December"];
		
		return months[index];
	}

	monthPT(index) {
		const months = ["Janeiro", "Fevereiro", "Março", "Abril",
				"Maio", "Junho", "Julho", "Agosto", "Setembro",
				"Outubro", "Novembro", "Dezembro"];

		return months[index];
	}

	formatDate(date, lang) {

		var dateObj = new Date(date);
		let month = lang == 'pt' ? this.monthPT(dateObj.getMonth()) : this.monthEN(dateObj.getMonth());
		return month + ' ' + this.formatTwoDigits(dateObj.getDate() + 1) + ', ' + dateObj.getFullYear();
	}

	disableScroll() {

		const html = jQuery('html');
		html.removeClass('with-smooth');
    	var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    	var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        window.onscroll = function() {
        	
            window.scrollTo(scrollLeft, scrollTop);
        };
	}

	enableScroll() {

		const html = jQuery('html');
		html.addClass('with-smooth');
    	window.onscroll = function() {};
	}

	getCookie(name) {

		if (!document.cookie) {
	    	return null;
	  	}

	  	const xsrfCookies = document.cookie.split(';')
	    .map(c => c.trim())
	    .filter(c => c.startsWith(name + '='));

	  	if (xsrfCookies.length === 0) {
		    return null;
	  	}

	  	return decodeURIComponent(xsrfCookies[0].split('=')[1]);
	}

	setCookie(key, value) {

		const d = new Date();
		d.setTime(d.getTime() + (180*24*60*60*1000)); // expires in 180 days
		let expires = "expires="+ d.toUTCString();
		document.cookie = key + "=" + value + ";" + expires + ";path=/";
	}

	removeAcentos(text) {   

	    text = text.toLowerCase();                                                         
	    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
	    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
	    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
	    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
	    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
	    text = text.replace(new RegExp('[Ç]','gi'), 'c');
	    return text;                 
	}

	getURLParam(string) {

	    const url = new URL(location.href);
	    const params = new URLSearchParams(url.search.slice(1));
	    if(params.get(string)) {
	        return params.get(string);
	    }

	    return false;
	}

	apenasNumeros(numero) {
		return numero.replace(/\D/g, '');
	}

	removeSideSpaces(node) {
		let value = node.value;
		value = value.replace(/ +/g, " ");
		value = value.trim();
		node.value = value;
	}

	handleNameField(element) {
		function notAllowedNumbers(e) {
			const keyCode = e.keyCode;
			const isNumber = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
			const isInitialOrConsecutiveSpace = keyCode === 32 && (e.currentTarget.value.length === 0 || /\s{1}$/.test(e.currentTarget.value));

			if (isNumber || isInitialOrConsecutiveSpace) {
				e.preventDefault();
			}
		}
		
		function capitalizeFirstLetterOfEachWord(node) {
			let text = node.value;
			node.value = text
				.split(" ")
				.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
				.join(" ");
		}

		function removeSideSpaces(node) {
			node.value = node.value.trim();
		}

		if (element) {
			element.addEventListener("keydown", function(e) {
				notAllowedNumbers(e);
			});

			element.addEventListener("input", function() {
				capitalizeFirstLetterOfEachWord(this);
			});

			element.addEventListener("blur", function() {
				removeSideSpaces(this);
				capitalizeFirstLetterOfEachWord(this);
			});
		}
	}

	handleEmailField(element) {
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	
		$(element).on("keydown", function (e) {
			const node = $(this);

			// Previne o uso da tecla de espaço
			if (e.keyCode == 32) e.preventDefault();

			// Converte o valor para minúsculas
			node.val(node.val().toLowerCase());
		});
	
		$(element).on("blur input", function (e) {
			const node = $(this);
			const value = node.val().toLowerCase();

			node.val(value);

			console.log(value.length)
			if(value.length > 0){
				if (!emailPattern.test(value)) {
					const inputElement = $('#email');
					const formControl = inputElement.closest('.form-control');

					let emailError = formControl.find('#email-error');

					formControl.next('#email-error').remove();
					if (emailError.length === 0) {
						emailError = $('<label id="email-error" class="error" for="email"></label>');
						formControl.after(emailError);
					}
					inputElement.addClass('error');
					emailError.text('Por favor, forneça um endereço de email válido.');
					emailError.show();

					// $("#btn-avancar").attr("disabled", true);
				} else {
					$(this).removeClass('error');
					// $(this).addClass('valid');
					// $('#email-error').text("");
					$('#email-error').hide();
					$("#btn-avancar").removeAttr("disabled")
				}
			}
		});
	}
	
	handlePhoneField(element) {
		const phoneRegex = /^\(?\d{2}\)?\s?\d{5}-\d{4}$/;
	
		function validatePhoneFormat(value) {
			return phoneRegex.test(value);
		}
	
		function enforcePhoneNumberFormat(e) {
			const keyCode = e.keyCode;
			const isNumber = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
			if (!isNumber && keyCode !== 8) {  // Only numbers and backspace
				e.preventDefault();
			}
		}
	
		function formatPhoneNumber(node) {
			let value = node.value.replace(/\D/g, "");  // Remove all non-digit characters
	
			// Format the number based on length
			if (value.length <= 10) {
				// Format (XX) XXXX-XXXX
				value = value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
			} else {
				// Format (XX) XXXXX-XXXX
				value = value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
			}
			node.value = value;
		}
	
		if (element) {
			element.addEventListener("keydown", function (e) {
				enforcePhoneNumberFormat(e);
			});
	
			element.addEventListener("blur", function () {
				formatPhoneNumber(this);
				console.log("Phone number", validatePhoneFormat(this.value))
				if (!validatePhoneFormat(this.value)) {
					
					this.focus();
				}
			});
		}
	}
	
	handleValidatedEmail() {
		const inputElement = document.getElementById("email");

		inputElement.classList.replace("valid", "error");
		$('#email-error').text('Por favor, forneça um endereço de email válido.');
		$('#email-error').show();
	}
}

export default new Utils();