import UTM from '../utm.js';

document.addEventListener('sebDocumentReady', function() {

	const contact = jQuery('.contact.schedule');

	if(contact.length > 0) {
		let utm_params = UTM.getLocalStorage();
		let utmCampaign = utm_params.utm_campaign;
		let utmContent = utm_params.utm_content;
		let utmSource = utm_params.utm_source;
		let utmTerm = utm_params.utm_term;
		let utmMedium = utm_params.utm_medium;

		contact.each(function() {

			const current = jQuery(this);
			const iframes = current.find('iframe');
			const name_dynamics = current.find('.right-column').data('dynamics');
			
			let obj = {

				mktCampaign: utmMedium ? utmMedium : '',
				mktContent: utmContent ? utmContent : '',
				mktName: utmCampaign ? utmCampaign :  '',
				mktSource: utmSource ? utmSource : '',
				mktTerm: utmTerm ? utmTerm : '',
				mktEscola: name_dynamics ? name_dynamics : ''
			};

			function sendMessageToIframe() {
				if(iframes.length > 0) {
					const iframe = iframes[0];
					const content = iframe.contentWindow;
					content.postMessage(obj, '*');
				}
			}

			function iframeAck(e) {

				if(!e.data) {
					return;
				}

				if(e.data.ready !== true) {
					return;
				}

				sendMessageToIframe();
			}

			for(let times = 1; times <= 4; times++) {
				setTimeout(() => {
					sendMessageToIframe();
				}, times * 1000);
			}

			window.addEventListener('message', iframeAck);
		});
	}
});