import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const lang = jQuery('html')[0].lang;
	const blog = jQuery('.blog');
	
	blog.each(function(index) {

		const current = jQuery(this);
		const form = current.find('form');

		const filters = current.find('.filter');
		filters.on('click', function() {

			filters.removeClass('show');
			const contents = filters.find('.options');
			contents.css('max-height', '0px');
			contents.find('li').css('opacity', '0');

			const element = jQuery(this);
			const content = element.find('.options');
			
			if(content.css('max-height') != '0px') {

				element.removeClass('show');
				content.css('max-height', '0px');
				content.find('li').css('opacity', '0');

			} else {

				element.addClass('show');
				content.css('max-height', Math.min(content[0].scrollHeight, 300) + 'px');
				content.find('li').css('opacity', '1');
			}
		});

		const categoryTitle = current.find('.filter-category .title');
		const unitTitle = current.find('.filter-unit .title');
		const orderTitle = current.find('.filter-order .title');
		function updateFilters() {

			const element = jQuery(this);
			const text = element.html();
			const value = element.data('value');
			const type = element.data('type');
			if(type == 'category') {

				categoryTitle.html(text);				
			
			} else if(type == 'unit') {

				unitTitle.html(text);
			
			} else if(type == 'order') {

				orderTitle.html(text);
			}

			form.find('[name="'+ type + '"]').val(value);
			form.submit();
		}
		
		const option = filters.find('li');
		option.on('click', updateFilters);
		form.on('submit', function() {

			form.find('input[type="hidden"][value=""]').attr('disabled', 'disabled');
			return true;
		});

		const btnSearch = form.find('.btn-search');
		btnSearch.on('click', function(e) {

			form.find('input[type="hidden"]').attr('disabled', 'disabled');
		})
	});
});