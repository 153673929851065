import 'jquery-mask-plugin';

document.addEventListener('sebDocumentReady', function() {

	const phoneMaskBehavior = function (val) {
  		return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
	};
	const phoneMaskOptions = {
  		onKeyPress: function(val, e, field, options) {
      		field.mask(phoneMaskBehavior.apply({}, arguments), options);
    	}
	};

	jQuery('.mask-phone').mask(phoneMaskBehavior, phoneMaskOptions);
	jQuery('.mask-cpf').mask('999.999.999-99')
	jQuery('.mask-birthdate').mask('99/99/9999')
});