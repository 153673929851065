import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {


    // Código para exibir os itens do carrossel que estão ocultos no carregamento inicial
    const carouselItems = jQuery('.carousel .carousel_items');
    carouselItems.each(function() {
        $( this ).removeClass( "carousel_items" );
    });

	const carouselOneColumn = jQuery('.carousel-one-column');
	carouselOneColumn.each(function(index) {

		const currentSection = jQuery(this);
		const carousel = currentSection.find('.carousel');
		const items = carousel.find('.item');
		
		var slider = tns({
			
			container: carousel[0],
			mouseDrag: true,
			controls: false,
			items: 1,
			nav: true,
			loop: true,
			touch: true,
			lazyload: true,
			autoplayTimeout: 4000,
			autoplay: false,
			autoplayButtonOutput: false,
			responsive: {

				941: {

					nav: false
				}
			}
		});

		if(items.length > 1) {

			const btnPrevious = currentSection.find('.navigation .btn-previous');
			btnPrevious.on('click', function() {
				slider.goTo('prev');
			});

			const btnNext = currentSection.find('.navigation .btn-next');
			btnNext.on('click', function() {
				slider.goTo('next');
			});
		} else {

			currentSection.find('.navigation').hide();
		}

		carousel.on('mouseenter', function() {

			slider.pause();
		});

		carousel.on('mouseleave', function() {
				
			slider.play();
		});

		currentSection.find('.button-scroll-bottom').on('click', function(event) {

	 		const next = currentSection.next();
	 		if(next) {

	 			var headerHeight = jQuery('header').height()
	 			headerHeight = headerHeight ? headerHeight : 0
	 			var cmsToolbarHeight = jQuery('.cms-toolbar').height()
	 			cmsToolbarHeight = cmsToolbarHeight ? cmsToolbarHeight : 0
	 			
	 			$('html,body').animate({
	                scrollTop: next.offset().top - headerHeight - cmsToolbarHeight
	            }, 1000);
	 		}
		});

		function resizeBanners() {

			const banners = currentSection.find('.tns-item');
			if(banners.length > 1) {

				banners.css('height', 'initial')

				setTimeout(function() {

					var maxHeight = 0;
					banners.each(function() {
						maxHeight = Math.max(maxHeight, jQuery(this).outerHeight())
					})

					banners.each(function() {
						jQuery(this).css('height', maxHeight+'px')
					})

				}, 100);
			}
		}

		window.addEventListener('resize', function(event) {
		    resizeBanners();	
		});
		resizeBanners();
	});
});