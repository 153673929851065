import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const animatedHomepage = jQuery('.animated-homepage');
	animatedHomepage.each(function(index) {

		const currentSection = jQuery(this);
		let nextSection = null;
		const html = jQuery("html");
		// Desktop
		const sidebar = currentSection.find('.sidebar');
		const blocksWithImage = currentSection.find('.block-with-bg-image');
		const firstBlock = currentSection.find('.first-block');
		const secondBlock = currentSection.find('.second-block');
		const lastBlock = currentSection.find('.last-block');
		const titlesController = sidebar.find('p.controller');
		const titlesDark = sidebar.find('p.dark');
		const titlesLight = sidebar.find('p.light');
		const indexesDark = sidebar.find('span.dark');
		const indexesLight = sidebar.find('span.light');
		const circles = sidebar.find('.circle');
		let scrollPos = 0;

		const calculatePercentage = function(actual, min, max) {

			if(actual <= min) {
				return 0;
			}

			if(actual >= max) {
				return 0;
			}

			let avg = (max + min) / 2;
			let total = avg - min;
			if(actual <= avg) {
				let value = actual - min;
				return value / total;
			} else {
				let value = actual - avg;
				return 1 - (value / total);
			}
		}

		const updateElements = function() {

			const currentViewHeight = document.documentElement.clientHeight;
			const currentCenter = currentViewHeight / 2 + window.pageYOffset;
			const initialOffset = currentSection[0].offsetTop + firstBlock[0].offsetTop + firstBlock[0].offsetHeight / 2;
			const endOffset = currentSection[0].offsetTop + lastBlock[0].offsetTop +  lastBlock[0].offsetHeight / 2;

			let newOffset = Math.max(currentCenter - firstBlock[0].offsetTop, initialOffset);
			newOffset = Math.min(newOffset, endOffset);
			sidebar.css('top', newOffset - currentSection[0].offsetTop);
					
			let percentage =  Math.max((currentCenter - initialOffset), 0) / (endOffset - initialOffset);
			percentage = Math.min(percentage, 1);
		
			titlesDark.css('opacity', 1 - Math.min((4 * percentage), 1));
			indexesDark.css('opacity', 1 - Math.min((4 * percentage), 1));
			titlesLight.css('opacity', Math.min((4 * percentage), 1));
			indexesLight.css('opacity', Math.min((4 * percentage), 1));	
			circles.eq(0).css('opacity', calculatePercentage(percentage, 0.25, 0.56));
			circles.eq(1).css('opacity', calculatePercentage(percentage, 0.55, 0.85));
			circles.eq(2).css('opacity', calculatePercentage(percentage, 0.82, 1.12));
			titlesDark.eq(0).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage, 0.25, 0.40))) + 'px');
			titlesDark.eq(1).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage, 0.55, 0.77))) + 'px');
			titlesDark.eq(2).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage,  0.82, 1.24))) + 'px');
			titlesDark.eq(0).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage, 0.25, 0.40))) + 'px');
			titlesDark.eq(1).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage, 0.55, 0.77))) + 'px');
			titlesDark.eq(2).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage,  0.82, 1.24))) + 'px');
			titlesLight.eq(0).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage, 0.25, 0.40))) + 'px');
			titlesLight.eq(1).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage, 0.55, 0.77))) + 'px');
			titlesLight.eq(2).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage,  0.82, 1.24))) + 'px');
			titlesLight.eq(0).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage, 0.25, 0.40))) + 'px');
			titlesLight.eq(1).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage, 0.55, 0.77))) + 'px');
			titlesLight.eq(2).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage,  0.82, 1.24))) + 'px');
			titlesController.eq(0).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage, 0.25, 0.40))) + 'px');
			titlesController.eq(1).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage, 0.55, 0.77))) + 'px');
			titlesController.eq(2).css('font-size', Math.floor(25  * (1+ calculatePercentage(percentage,  0.82, 1.24))) + 'px');
			titlesController.eq(0).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage, 0.25, 0.40))) + 'px');
			titlesController.eq(1).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage, 0.55, 0.77))) + 'px');
			titlesController.eq(2).css('line-height', Math.floor(35  * (1+ calculatePercentage(percentage,  0.82, 1.24))) + 'px');
		}

		const items = sidebar.find('.item');
		items.on('click', function() {

			const index = jQuery(this).data('index');
			Utils.scrollToElement(blocksWithImage[index - 1]);
		});

		function getNextSection() {
			const nextElements = currentSection.nextAll('div');
		
			nextElements.each(function() {
				if(!nextSection){
					if(jQuery(this).css('display') !== 'none'){
						nextSection = jQuery(this);
						nextSection.css("scroll-snap-align", "start")
						return;
					}
				}
			})
		}

		getNextSection()

		if(firstBlock.length > 0 && lastBlock.length > 0) {

			window.addEventListener('scroll', function() {
		 		
				requestAnimationFrame(updateElements);

				// if(animatedHomepage.isInViewport()) {
				// 		if ((document.body.getBoundingClientRect()).top > scrollPos) {
				// 			if(firstBlock.isInViewport()) {
				// 				html.css("scroll-snap-type", "none")
				// 			}
				// 			if(lastBlock.isInViewport()) {
				// 				html.css("scroll-snap-type", "y mandatory")
				// 			}
				// 		} else {
				// 			if(secondBlock.isInViewport()) {
				// 				html.css("scroll-snap-type", "y mandatory")
				// 				html.css("scroll-padding", "92px")
				// 			}
				// 			if(nextSection.isInViewport()) {
				// 				html.css("scroll-snap-type", "none")
				// 			}
				// 		}
				// 		scrollPos = (document.body.getBoundingClientRect()).top;
					  
				// }

			});

			updateElements();
		}

		$.fn.isInViewport = function() {
			var elementTop = $(this).offset().top;
			var elementBottom = elementTop + $(this).outerHeight();
			var viewportTop = $(window).scrollTop();
			var viewportBottom = viewportTop + $(window).height();
			return elementBottom > viewportTop && elementTop < viewportBottom;
		};

		// Mobile
		const carousel = currentSection.find('.carousel');		
		var slider = tns({
			
			container: carousel[0],
			mouseDrag: true,
			controls: false,
			items: 1.2,
			nav: false,
			loop: false,
			touch: true,
			responsive: {

				941: {

					disable: true
				}
			}
		});
		
		function resizeBanners() {

			const banners = currentSection.find('.tns-item');
			if(banners.length > 1) {

				banners.css('height', 'initial')

				setTimeout(function() {

					var maxHeight = 0;
					banners.each(function() {
						maxHeight = Math.max(maxHeight, jQuery(this).outerHeight())
					})

					banners.each(function() {
						jQuery(this).css('height', maxHeight+'px')
					})

				}, 100);
			}
		}

		window.addEventListener('resize', function(event) {
		    resizeBanners();	
		});
		resizeBanners();
	});
});

if(window.innerWidth <= 940) {

	const firstBlock = jQuery('.animated-homepage .first-block');
	firstBlock.attr('data-aos-offset', 300);
}