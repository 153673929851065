import 'jquery-validation';
import Utils from '../utils.js';
import UTM from '../utm.js';

document.addEventListener('sebDocumentReady', function() {

	const contact = jQuery('.contact .form-contact');

	if(contact.length > 0) {

		const subjectSelect = contact.find('select[name="subject"]');
		let pendingSubject = false;
		if(subjectSelect.length > 0) {
			pendingSubject = true;
			fetch('/api/subjects/?site='+UNIT_INFO.site_id)
			.then(response => response.json())
			.then(function(response) {

				subjectSelect.each(function() {

					for(var i = 0; i < response.length; i++) {

						this.append(new Option(response[i].title, response[i].title));
					}
				});

				pendingSubject = false;
				if(!pendingUnits) {
					generateForm();
				}
			})
		}

		const unitsSelect = contact.find('select[name="unit-school"]');
		let pendingUnits = false;
		if(unitsSelect.length > 0) {
			pendingUnits = true;

			fetch('/api/units').then(response => response.json())
			.then(function(data) {

				unitsSelect.each(function() {

					for(var i = 0; i < data.length; i++) {

						this.append(new Option(data[i].name, data[i].name));
					}
				});

				pendingUnits = false;
				if(!pendingSubject) {
					generateForm();
				}
			})
		}


		function generateForm() {

			contact.each(function() {

				const current = jQuery(this);
				const currentForm = current.find('form');
				const feedbackMessage = current.find('.feedback-message');
				const feedbackError = current.find('.feedback-error');
				current.removeClass('is-loading');

				var rules = {
					'name': {
						required: true,
						minlength: 3,
						maxlength: 100
					},
					'email': {
						required: true,
						email: true,
						minlength: 3,
						maxlength: 100
					},
					'phone': {
						required: true,
						minlength: 14,
						maxlength: 15,
					},
					'student-name': {
						required: true,
						minlength: 3,
						maxlength: 100
					},
					'subject': {
						required: true,
					},
					'unit-school': {
						required: true,
					},
					'message': {
						required: true,
						minlength: 3,
						maxlength: 500
					},
				};

				currentForm.validate({
					rules: rules,
					messages: {
						'phone': {
							'minlength': "Por favor, forneça ao menos 10 números.",
						}
					},
					submitHandler: function(form) {

						const captcha = grecaptcha.getResponse();
						if(!captcha) {

							current.find('.recaptcha-feedback').show();
							return;
						
						} else {
						
							current.find('.recaptcha-feedback').hide();
						}

						const csrfToken = Utils.getCookie('csrftoken');
						const data = {
							'name': currentForm.find('input[name="name"]').val(),
							'student_name': currentForm.find('input[name="student-name"]').val(),
							'email': currentForm.find('input[name="email"]').val(),
							'phone': currentForm.find('input[name="phone"]').val(),
							'subject': currentForm.find('select[name="subject"]').val(),
							'unit': currentForm.find('select[name="unit-school"]').val(),
							'message': currentForm.find('textarea[name="message"]').val(),
							'site_id': UNIT_INFO.site_id,
							'site_name': UNIT_INFO.site_name,
							'selection': 'Ouvidoria',
							'recaptcha_codigo': captcha
						};

						const utm_params = UTM.getUTM();

						const formData = {
							...utm_params,
							...data
						}

						feedbackError.hide();
						current.addClass('is-loading');
						fetch('/api/contact/', {
							method: 'POST',
							headers: {
					      		'Accept': 'application/json',
					      		'X-CSRFToken': csrfToken,
					      		'Content-Type': 'application/json'
				    		},
							body: JSON.stringify(formData)
						}).then((response) => {

							if(response.ok) {
								return true;
							}
							return false;
						
						}).then(function(response) {

							if(response) {

								currentForm.hide();
								feedbackMessage.show();

								if(!Utils.isElementInViewport(feedbackMessage[0])) {

									Utils.scrollToElement(feedbackMessage[0]);
								}

							} else {

								feedbackError.show();
							}

							current.removeClass('is-loading');
						});

						return false;
					},
				})
			});
		}
	}
});