import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const map = jQuery('.map');
	map.each(function(index) {

		const current = jQuery(this);
		const svg = current.find('.map-box svg');
		const mapUnits = mapUnitsJSON;
	
		for (let i = 0; i < mapUnits.length; i++) {

			const id = mapUnits[i].toLowerCase();
			const country = svg.find('#' + id);
			country.addClass('active');
			const area = svg.find('#' + id + '-area');
			area.addClass('active');

			area.on('mouseenter', function() {
				country.addClass('expanded');
			});
			area.on('mouseleave', function() {
				country.removeClass('expanded');
			});
		}
	});
});