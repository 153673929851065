document.addEventListener('DOMContentLoaded', function () {
    
  const mask = jQuery('.square-image-video .square-item:first').find('.mask');
  const playButton = jQuery('.square-image-video .square-item:first').find('.play');

  mask.css('display', 'block');
  playButton.css('display', 'block');

  jQuery('.square-image-video .square-item').on('click', function() {

    const videoId = jQuery(this).data('video-id');
    
    if(videoId){
      jQuery('.modal-video:first iframe').attr('src', "https://www.youtube.com/embed/" + videoId + "?autoplay=1");
      jQuery('.modal-video:first').addClass("show");
    }
  }); 

  jQuery('.close-button-modal').on('click', function() {
      jQuery('.modal-video').removeClass("show");
      jQuery('.modal-video:first iframe').attr('src', "https://www.youtube.com/embed/?autoplay=0");
  }); 


  if(jQuery('.square-image-video').length > 0) {
      jQuery('.modal-video').on('click',function(event){
          if(!jQuery(event.target).is('.square-video')){
              jQuery('.modal-video').removeClass("show");
              jQuery('.modal-video:first iframe').attr('src', "https://www.youtube.com/embed/?autoplay=0");
          }
      });
  }
});