document.addEventListener('sebDocumentReady', function() {

	const dropdown = jQuery('.dropdown');
	dropdown.each(function(index) {

		const currentSection = jQuery(this);
		const dropdownItems = currentSection.find('.dropdown-item:not(.has-document)');
		const dropdownTitles = dropdownItems.find('.dropdown-title');

		dropdownTitles.on('click', function() {

			const current = jQuery(this).parent();
			const content = current.find('.dropdown-content');

			current.toggleClass('show');
			if(content.css('max-height') != '0px') {

				content.css('max-height', '0px');

			} else {

				content.css('max-height', content[0].scrollHeight + 'px');
			}
		});
	});
});