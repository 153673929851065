class Utm {

    keyUTM = 'utm_params'

    validUtmParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
        "utm_name",
    ]

    getLocalStorage() {
        let utmString = localStorage.getItem(this.keyUTM);
        return utmString ? JSON.parse(utmString) : {};
    }

    getFromLocalStorage() {
        return this.getLocalStorage();
    }

    saveFromURL() {
        const utms = this.getFromURL();
        if (!jQuery.isEmptyObject(utms)) {
            localStorage.setItem(this.keyUTM, JSON.stringify(utms))
        }
    }

    getFromURL() {
        let current_params = new URLSearchParams(window.location.search);
        let utms = {}
        current_params.forEach((value, key) => {
            if (this.validUtmParams.includes(key)) {
                utms[key] = value
            }
        })
        return utms
    }

    isObjectEmpty(obj) {
		return Object.keys(obj).length === 0;
	}

    getUTM() {
        let utm_params = this.getFromURL();
        if (this.isObjectEmpty(utm_params)) {
            utm_params = this.getFromLocalStorage();
        }
        return utm_params;
    }
}

export default new Utm();