import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const allStates = {
		ac:'acre',
		al:'alagoas',
		ap:'amapa',
		am:'amazonas',
		ba:'bahia',
		ce:'ceara',
		es:'espirito santo',
		go:'goias',
		ma:'maranhao',
		mt:'mato grosso',
		ms:'mato grosso do sul',
		mg:'minas gerais',
		pa:'para',
		pb:'paraiba',
		pr:'parana',
		pe:'pernambuco',
		pi:'piaui',
		rj:'rio de janeiro',
		rn:'rio grande do norte',
		rs:'rio grande do sul',
		ro:'rondônia',
		rr:'roraima',
		sc:'santa catarina',
		sp:'sao paulo',
		se:'sergipe',
		to:'tocantins',
		df:'distrito federal',
	};

	const resultsUnits = jQuery('.results-units');
	resultsUnits.each(function(index) {

		const current = jQuery(this);
		const lang = jQuery('html')[0].lang;
		const iconAddress = current.find('.icon-hidden.location').html();
		const iconPhone = current.find('.icon-hidden.phone').html();
		const iconGlobe = current.find('.icon-hidden.globe').data('url');
		const iconArrowSVG = current.find('.icon-hidden.arrow').html();
		const paginationBlock = current.find('.pagination-block');
		const grid = current.find('.grid');

		let allUnits = [];
		let searchForUnits = Utils.getURLParam('q');
		searchForUnits = searchForUnits || '';
		let query = Utils.removeAcentos(searchForUnits.toLowerCase().replace('-', ''));
		let unitsToRender = [];

		function createPagination(isFirstRender = false) {

			paginationBlock.removeClass('not-found');
			paginationBlock.removeClass('hide-pagination');
			unitsToRender = [];

			for(let i = 0; i < allUnits.length; i++) {
				let currentUnit = allUnits[i];
				let name = Utils.removeAcentos(currentUnit.name.toLowerCase());
				let cep = currentUnit.cep.replace('-', '');
				let extraCep = currentUnit.extra_cep ? currentUnit.extra_cep.replace(/[\s-]/g,'').split(',') : [];
				let relatedSchools = currentUnit.related_schools.length !== 0 ? currentUnit.related_schools.map(s=>s.replace('-', '')) : [];
				let city = Utils.removeAcentos(currentUnit.city.toLowerCase());
				let address = currentUnit.logradouro ? Utils.removeAcentos(currentUnit.logradouro.toLowerCase()) : '';
				let state = Utils.removeAcentos(currentUnit.province.toLowerCase());
				let fullState = Utils.removeAcentos(allStates[state] ? allStates[state] : '');

				if(!currentUnit.stringSegments) {
					currentUnit.stringSegments = Utils.removeAcentos(currentUnit.segments.join(', ').toLowerCase());
				}
				
				let segments = currentUnit.stringSegments;
				if(query.replace('-', '') === cep) {
					unitsToRender.push(currentUnit);
					refreshPagination(isFirstRender);
					continue;
				}

				if(	cep.includes(query) || city.includes(query) ||
					address.includes(query) || state.includes(query) ||
					segments.includes(query) || name.includes(query) ||
					fullState.includes(query) || relatedSchools.includes(query) || extraCep.includes(query) ) {
						unitsToRender.push(currentUnit);
				}
			}

			refreshPagination(isFirstRender);
		}

		const postsPerPage = function() {

			return jQuery(document).width() > 940 ? 12 : 6;
		}

		function refreshPagination(isFirstRender) {

			if(!isFirstRender) {
				paginationBlock.pagination('destroy');
			}
			
			paginationBlock.pagination({
			    dataSource: unitsToRender,
			    pageSize: postsPerPage(),
			    callback: function(data) {
			        let html = renderUnits(data);
			        grid.html(html);
			    },
			    prevText: iconArrowSVG,
			    nextText: iconArrowSVG,
			});

			if(unitsToRender.length == 0) {

				paginationBlock.addClass('not-found');
			
			} else if(unitsToRender.length <= postsPerPage()) {

				paginationBlock.addClass('hide-pagination');
			}
		}

		function renderUnits(units) {

			let elementsHTML = '';
			for(let i = 0; i < units.length; i++) {

				let unit = units[i];
				elementsHTML += '<div class="item"><img class="lazy" alt="" width="" height="" src="';
				elementsHTML += unit.get_image_url + '" /><div class="body"><div>';
				elementsHTML += '<p class="location">' + unit.city + ' - ' + unit.province + '</p>';	

				if(unit.secondary_link) {
					elementsHTML += '<a href="' + unit.secondary_link + '" class="title"';

					if(unit.secondary_link_target) {
						elementsHTML += ' target="_blank"';
					}
					elementsHTML += '>' + unit.name + '</a><div>';

				} else {
					elementsHTML += '<h4 class="title">'+ unit.name + '</h4><div>';
				}

				if(unit.logradouro) {

					elementsHTML += '<a';
					if(unit.address_url) {
						elementsHTML += ' href="' + unit.address_url + '" class="item-link has-link" target="_blank">';
					} else {
						elementsHTML += ' class="item-link">';
					}
					elementsHTML += iconAddress + unit.logradouro + '</a>';					
				}

				if(unit.phone) {
					elementsHTML += '<a href="tel:0' + unit.phone + '" class="item-link has-link" target="_blank">';
					elementsHTML += iconPhone + Utils.formatPhone(unit.phone) + '</a>';					
				}

				elementsHTML += '</div>';

				const description = unit.segments.join(', ');
				elementsHTML += '<p class="description">' + description + '</p>';

				elementsHTML += '</div>';

				if(unit.primary_link || unit.secondary_link) {

					elementsHTML += '<div class="columns"><div class="buttons">';
					if(unit.primary_link) {

						elementsHTML += '<a href="';
						elementsHTML += unit.primary_link;
						elementsHTML += '" class="btn btn-primary"' + (unit.primary_link_target ? ' target="_blank">' : ">");
						elementsHTML += '<span>' + (lang == 'pt' ? 'Agende uma visita' : '') + (lang == 'es' ? 'Programar una visita' : '') + (lang == 'en' ? 'Schedule a visit' : '') + '</span></a>';					
					}
					if(unit.secondary_link) {

						elementsHTML += '<a href="';
						elementsHTML += unit.secondary_link;
						elementsHTML += '" class="btn btn-secondary bg-light"' + (unit.secondary_link_target ? ' target="_blank">' : ">");
						elementsHTML += '<span>' + (lang == 'pt' ? 'Conheça a unidade' : '') + (lang == 'es' ? 'Conocer la unidad' : '') + (lang == 'en' ? 'Know the unit' : '') + '</span></a>';					
					}
					elementsHTML += '</div>';

					if(unit.address_url) {

						elementsHTML += '<a href="' + unit.address_url + '" class="link-globe" target="_blank">';
						elementsHTML += '<img class="lazy" src="' + iconGlobe + '" width="39" height="39" alt="" />';
						elementsHTML += '</a>';

					} else {	
						elementsHTML += '<img class="lazy" style="visibility: hidden;" src="' + iconGlobe + '" width="39" height="39" alt="" />';
					}
	
					elementsHTML += '</div>';
				}

				elementsHTML += '</div></div>';
			}

			return elementsHTML;
		}


		let viaCepResponse = '';
		let viaCepOk = true;
		let cepSearch = query.replace('-', '');
		let isCep = (cepSearch.length === 8 && (/^\d+$/).test(cepSearch)) ? true : false;
		
		if(isCep) {
			viaCepOk = false;
			fetch('https://viacep.com.br/ws/'+ cepSearch + '/json/').then(response => response.json())
			.then((data) => {

				viaCepResponse = data.localidade ? Utils.removeAcentos(data.localidade.toLowerCase()) : '';
				if(unitsOK) {
					showResults();
				}

				viaCepOk = true;
			});
		}

		let unitsOK = false;
		fetch('/api/units').then(response => response.json())
		.then(function(data) {

			allUnits = data;

			if(viaCepOk) {
				showResults();
			}

			unitsOK = true;
		})

		function showResults() {

			paginationBlock.removeClass('is-loading')
			createPagination(true);
			window.addEventListener('resize', function(event) {
			    createPagination();
			});
		}
	});
});