import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const header = jQuery('header');
	if(header.length > 0) {

		const btnToggleMenu = header.find('.btn-toggle-menu');
		const headerContainer = header.find('.header-container');
		btnToggleMenu.on('click', function() {

			if(headerContainer.hasClass('show')) {

				headerContainer.removeClass('show');
				Utils.enableScroll();
			
			} else {

				headerContainer.addClass('show');
				Utils.disableScroll();
			}
		});

		const bgCloseMenu = header.find('.bg-close');
		bgCloseMenu.on('click', function() {

			headerContainer.removeClass('show');
			Utils.enableScroll();
		});

		const btnToggleFlags = headerContainer.find('.language-options');
		btnToggleFlags.on('click', function() {

			if(btnToggleFlags.hasClass('show')){

				btnToggleFlags.removeClass('show');	
			} else {

				btnToggleFlags.addClass('show');
			}
		});

		const floatingButton = headerContainer.find('.btn');
		const floatingButtons = jQuery('body div.floating-buttons');

		if(floatingButton.length > 0 && floatingButtons.length > 0){

			const newButton = floatingButton.clone();
			floatingButtons.append(newButton);
		}

		const dropdownMenuTitle = header.find('.dropdown-menu-title');

		function closeAllSubMenus() {

			dropdownMenuTitle.each(function() {

				const current = jQuery(this);
				const subMenu = current.parent().find('.dropdown-sub-menu');
				subMenu.css('max-height', '0px');
				subMenu.css('opacity', '0');
				current.removeClass('show');
			});
		}

		dropdownMenuTitle.on('click', function() {

			const current = jQuery(this);
			const subMenu = current.parent().find('.dropdown-sub-menu');

			if(subMenu.css('max-height') != '0px') {

				subMenu.css('max-height', '0px');
				subMenu.css('opacity', '0');
				current.removeClass('show');

			} else {

				closeAllSubMenus();
				subMenu.css('max-height', subMenu[0].scrollHeight + 'px');
				subMenu.css('opacity', '1');
				current.addClass('show');
			}
		});
	}
});