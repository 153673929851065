import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";

document.addEventListener('sebDocumentReady', function() {

	const segments = jQuery('.segments');
	segments.each(function(index) {

		const currentSection = jQuery(this);
		const carousel = currentSection.find('.carousel');
		var slider = tns({
			
			container: carousel[0],
			mouseDrag: true,
			controls: false,
			nav: false,
			loop: false,
			touch: true,
			items: 1.22,
			responsive: {

				941: {

					items: 3,
				}
			}
		});

		function resizeItems() {

			const items = currentSection.find('.tns-item');
			if(items.length > 1) {

				items.css('height', 'initial')

				setTimeout(function() {

					var maxHeight = 0;
					items.each(function() {
						maxHeight = Math.max(maxHeight, jQuery(this).outerHeight())
					})

					items.each(function() {
						jQuery(this).css('height', maxHeight+'px')
					})

				}, 100);
			}
		}

		window.addEventListener('resize', function(event) {

		    resizeItems();
		});

		resizeItems();
	});
});