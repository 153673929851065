import 'jquery-validation';

import Utils from '../utils.js';
import Tracking from '../tracking.js';

document.addEventListener('sebDocumentReady', function() {

	var unitsLoaded = false;
	var segmentsLoaded = false;
	var acknowledgementsLoaded = false;
	var statesLoaded = false;

	var onlineCalendar = {}
	var presencialCalendar = {}

	function canRemoveIsLoading() {

		if(unitsLoaded && acknowledgementsLoaded && statesLoaded) {

			return true;
		}

		return false;
	}

	function setIsLoading(element) {
		element.addClass('is-loading')
	}
	function unsetIsLoading(element) {
		element.removeClass('is-loading')
	}

	function loadUnits(element) {
		let selectedUnits = jQuery(".scheduleSeb").data("selected-schools");
		selectedUnits = selectedUnits.replace(/\s+/g, "");
		selectedUnits = selectedUnits.replace(/\[/g, "");
		selectedUnits = selectedUnits.replace(/\]/g, "");
		selectedUnits = selectedUnits.replace(/'/g, "");

		setIsLoading(element);

		fetch("/dynamics/units/", {
			method: "GET",
		}).then((response) => response.json()).then(function (response) {
			const label = "Escola de interesse*";

			const select = element.find('select[name="unit_schedule"]');
			select.empty().append('<option value="" disabled selected>' + label + "</option>");

			let filteredResponse;

			if (selectedUnits == "None") {
				filteredResponse = response;
			} else {
				filteredResponse = response.filter((res) =>
				selectedUnits.includes(res.businessunitid)
				);
			}

			for (let i = 0; i < filteredResponse.length; i++) {
				const title = filteredResponse[i].seb_nomelandingpage;
				const value = filteredResponse[i].businessunitid;
				select.append('<option value="' + value + '">' + title + "</option>");
			}

			select.addClass("loaded");

			unitsLoaded = true;
			if (canRemoveIsLoading()) {
				unsetIsLoading(element);
			}
		});
	}


	function attachUnitListener(element) { 

		const unitSelect = element.find('select[name="unit_schedule"]')
		unitSelect.on('change', function() {

			const unit_id = jQuery(this).val();
			loadSegments(element, unit_id);
		})
	}

	function loadSegments(element, unit_id) {

		const select = element.find('select[name="series_schedule"]');
		select.empty().append('<option value="" disabled selected>'+"Aguarde, carregando..."+'</option>');

		fetch('/dynamics/courses/'+unit_id+'/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			const label = 'Selecione a série desejada*'

			select.empty().append('<option value="" disabled selected>'+label+'</option>');
			for(var i = 0; i < response.length; i++) {

				const title = response[i].ptr_nomelandingpage
				const value = response[i]._ptr_segmento_value
				const cursounidade = response[i].ptr_cursodaunidadeid
				select.append('<option value="'+value+
					'" data-cursounidade="'+cursounidade+
					'">'+title+'</option>');
			}

			select.addClass('loaded');

			segmentsLoaded = true;
		})
	}

	function loadAcknowledgments(element) {

		setIsLoading(element)

		fetch('/dynamics/find_out/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			const label = 'Onde ficou sabendo da escola*';

			const select = element.find('select[name="acknowledgment_schedule"]');
			select.empty().append('<option value="" disabled selected>'+label+'</option>');
			for(var i = 0; i < response.length; i++) {

				const title = response[i].ptr_name
				const value = response[i].ptr_fonteid
				select.append('<option value="'+value+'">'+title+'</option>');
			}

			select.addClass('loaded');

			acknowledgementsLoaded = true;
			if(canRemoveIsLoading()) {

				unsetIsLoading(element)
			}
		})
	}

	function loadStates(element) {

		setIsLoading(element)

		fetch('/dynamics/states/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			const label = 'Estado onde estuda atualmente*';

			const select = element.find('select[name="stateorigin"]');

			select.empty().append('<option value="" disabled selected>'+label+'</option>');
			for(var i = 0; i < response.length; i++) {

				const title = response[i].ptr_name;
				const value = response[i].ptr_estadoid;
				select.append('<option value="'+value+'">'+title+'</option>');
			}

			select.addClass('loaded');

			statesLoaded = true;
			if(canRemoveIsLoading()) {

				unsetIsLoading(element)
			}
		})
	}

	function attachStateListener(element) {

		const stateSelect = element.find('select[name="stateorigin"]')
		stateSelect.on('change', function() {

			const state_id = jQuery(this).val()
			loadCities(element, state_id)
		})
	}

	function loadCities(element, state_id) {

		setIsLoading(element)

		fetch('/dynamics/states/'+state_id+'/cities/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			const label = 'Cidade onde estuda atualmente *';

			const select = element.find('select[name="cityorigin"]');
			select.empty().append('<option value="" disabled selected>'+label+'</option>');
			for(var i = 0; i < response.length; i++) {

				const title = response[i].ptr_name
				const value = response[i].ptr_cidadeid
				select.append('<option value="'+value+'">'+title+'</option>');
			}

			select.addClass('loaded');

			unsetIsLoading(element)
		})
	}

	function attachCityListener(element) {

		const stateSelect = element.find('select[name="cityorigin"]')
		stateSelect.on('change', function() {

			const city_id = jQuery(this).val()
			loadSchools(element, city_id)
		})
	}

	function loadSchools(element, city_id) {

		setIsLoading(element)

		fetch('/dynamics/schools/'+city_id, {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			const label = 'Escola de origem*';

			const select = element.find('select[name="schoolorigin"]');
			select.empty().append('<option value="" disabled selected>'+label+'</option>');
			for(var i = 0; i < response.length; i++) {

				const title = response[i].ptr_name
				const value = response[i].ptr_escoladeorigemid
				select.append('<option value="'+value+'">'+title+'</option>');
			}

			select.addClass('loaded');
			unsetIsLoading(element)
		})
	}

	function loadCalendar(element, unit_id) {

		setIsLoading(element)

		fetch('/dynamics/calendar/' + unit_id + '/', {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {
			onlineCalendar = response.online
			presencialCalendar = response.presencial

			element.find('form .datepicker-schedule').datepicker('refresh')

			const calendar = jQuery('[name="type"]:checked').val() == 'Presencial' ? presencialCalendar : onlineCalendar
			if(Object.keys(calendar).length > 0) {

				jQuery('.no-results').hide()
				jQuery('.date-and-time').show()

				const firstDay = Object.keys(calendar)[0];
				const firstDayAdjusted = firstDay.split('-').reverse().join('/')
				element.find('form .datepicker-schedule').datepicker('setDate', firstDayAdjusted)
				refreshHours()

			} else {

				jQuery('.no-results').show()
				jQuery('.date-and-time').hide()
			}

			unsetIsLoading(element)
		})
	}

	function isDateAvailable(date) {

		const year = date.getFullYear()
		const month = ("0" + (date.getMonth() + 1)).slice(-2)
		const day = ("0" + date.getDate()).slice(-2)

		const key = year + '-' + month + '-' + day

		const calendar = jQuery('[name="type"]:checked').val() == 'Presencial' ? presencialCalendar : onlineCalendar
		return calendar.hasOwnProperty(key)
	}

	function refreshHours() {

		const calendar = jQuery('[name="type"]:checked').val() == 'Presencial' ? presencialCalendar : onlineCalendar
		const date = jQuery('form .datepicker-schedule').datepicker('getDate');

		const year = date.getFullYear()
		const month = ("0" + (date.getMonth() + 1)).slice(-2)
		const day = ("0" + date.getDate()).slice(-2)
		const key = year + '-' + month + '-' + day

		const hoursArray = calendar[key]

		const timeOptions = jQuery('.hour-options')
		timeOptions.empty()

		for(var i = 0; i < hoursArray.length; i++) {

			let active = ''
			if(i==0) {
				active = 'active';
				jQuery('#time').val(hoursArray[i].name);
				jQuery('#idTime').val(hoursArray[i].id);
			}
			const option = `<div class="hour ${active}" data-hour="${hoursArray[i].name}" data-id="${hoursArray[i].id}">${hoursArray[i].name}</div>`;
			timeOptions.append(option)
		}

		
		jQuery('.hour').on('click', function() {
			jQuery('.hour').removeClass('active');
			let time = $(this).data('hour');
			let idTime = $(this).data('id');
			jQuery('#time').val(time);
			jQuery('#idTime').val(idTime);
			jQuery(this).addClass('active');
		});
	}

	const scheduleSeb = jQuery('.scheduleSeb');
	scheduleSeb.each(function(index) {
		const current = jQuery(this);
		const form = jQuery(this).find('form');

		const radio = current.find('input[type="radio"][name="visitor"]');
		radio.on('change', function() {

			const name = jQuery(this).val();

			if(name == 'Aluno') {

				form.addClass('only-student')
				form.removeClass('only-responsible')
				jQuery('[name="name_student"]').hide();
				jQuery('[name="cpf"]').attr("placeholder", "Seu CPF ou CPF do responsável*");

			
			} else {

				form.removeClass('only-student')
				form.addClass('only-responsible')
				jQuery('[name="name_student"]').show();
				jQuery('[name="cpf"]').attr("placeholder", "Seu CPF*");
			}

			if(validated) {

				validator.form() 
			}
		});

		jQuery('[name="birth_date"]').on('focusout', function(){
			if(isValidDate($(this).val()) === false) {
				$(this).addClass('error');
			} else {
				$(this).removeClass('error');
			}
		});

		function isValidDate(str) {
			const convertDate = str.split('/').reverse().join('-');
			return !!new Date(convertDate).getTime();
		}

		current.find('[type="radio"][name="type"]').on('change', function() {

			current.find('form .datepicker-schedule').datepicker('refresh')

			const calendar = current.find('[name="type"]:checked').val() == 'Presencial' ? presencialCalendar : onlineCalendar
			if(Object.keys(calendar).length > 0) {

				jQuery('.no-results').hide()
				jQuery('.date-and-time').show()
				jQuery('#btnAgendar').prop('disabled', false);


				const firstDay = Object.keys(calendar)[0];
				const firstDayAdjusted = firstDay.split('-').reverse().join('/')
				current.find('form .datepicker-schedule').datepicker('setDate', firstDayAdjusted)
				refreshHours()

			} else {

				jQuery('.no-results').show()
				jQuery('.date-and-time').hide()
				jQuery('#btnAgendar').prop('disabled', true);

			}
		});

		loadUnits(current)
		attachUnitListener(current)
		loadAcknowledgments(current)
		loadStates(current)
		attachStateListener(current);
		attachCityListener(current);

		const datePickers = current.find('form .datepicker-schedule');
		datePickers.each(function(index) {

			const datePicker = jQuery(this);
			const inputDate = datePicker.find('.input-date');
			datePicker.datepicker({
				beforeShowDay: function(date) {
					return [isDateAvailable(date)]
				},
				onSelect: function(dateText) {
					refreshHours()
        		},
				minDate: new Date(),
				dateFormat: 'dd/mm/yy',
				showWeek: false,
				monthNames: [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
				dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
			});

			inputDate.val(datePicker.val());
		});

		function formatDateToFetch(date) {
			const new_date = date.split('/');
			return new_date[2] + '-' + new_date[1] + '-' + new_date[0];
		}

		const step1Indicator = current.find('#nav1');
		const step2Indicator = current.find('#nav2');
		const step1 = current.find('.step.step1');
		const step2 = current.find('.step.step2');

		step1Indicator.on('click', function() {
			if(step2Indicator.hasClass('active')) {

				step1Indicator.addClass('active');
				step2Indicator.removeClass('active');
				step1.addClass('active');
				step2.removeClass('active');
			}
		})

		function handleSuccess() {

			if(step1Indicator.hasClass('active')) {
					
				step1Indicator.removeClass('active');
				step2Indicator.addClass('active');
				step1.removeClass('active');
				step2.addClass('active');

				const unit_id = jQuery('[name="unit_schedule"]').val()

				loadCalendar(current, unit_id)
			
			} else if(step2Indicator.hasClass('active')) {
				
				const stepNav = current.find('.stepNav');
				const formBoxes = current.find('.form-box');
				const successMessage = current.find('.success-form');
				stepNav.hide();
				formBoxes.hide();
				successMessage.show();

				current.removeClass('is-loading');
			}
		}

		const dependsOnFirstStep = function(element) {
  			return step1Indicator.hasClass('active');
		}

		const dependsOnSecondStep = function(element) {
  			return step2.hasClass('active');
		}

		const dependsOnResponsible = function(element) {
  			return form.find('[name="visitor"]:checked').val() == 'Responsável' && dependsOnFirstStep();
		}

		var validated = false;
		const dynamicsErrorMessage = current.find('.erro-dynamics')
		const cpfErrorMessage = current.find('.erro-cpf')
		const schoolsRequired = current.data('schools-required');

		const validator = form.validate({
			rules: {
				name_visitor: {
					required: {
						depends: dependsOnFirstStep
					},
					minlength: 3,
					maxlength: 100
				},
				cpf: {
					required: {
						depends: dependsOnResponsible
					},
					cpf: true
				},
				birth_date: {
					required: {
						depends: dependsOnFirstStep
					},
					validDate: true
				},
				email: {
					required: {
						depends: dependsOnFirstStep
					},
					email: true,
					minlength: 3,
					maxlength: 100
				},
				phone: {
					required: {
						depends: dependsOnFirstStep
					},
					minlength: 14,
					maxlength: 15
				},
				stateorigin: {

					required: schoolsRequired == 'True' ? true :  false
				},
				cityorigin: {
					
					required: schoolsRequired == 'True' ? true :  false
				},
				schoolorigin: {
					
					required: schoolsRequired == 'True' ? true :  false
				},
				unit_schedule: {
					required: {
						depends: dependsOnFirstStep
					},
				},
				series_schedule: {
					required: {
						depends: dependsOnFirstStep
					},
				},
				name_student: {
					required: {
						depends: dependsOnResponsible
					},
					minlength: 3,
					maxlength: 100
				},
				acknowledgment_schedule: {
					required: {
						depends: dependsOnFirstStep
					},
				},
				agree1: {
					required: {
						depends: dependsOnSecondStep
					},
				},
				agree2: {
					required: {
						depends: dependsOnSecondStep
					},
				}
			},
			errorPlacement: function(error, element) {

				var type = element.attr('type');
				validated = true;

				if(element.parent().hasClass('form-control')) {

					error.insertAfter(element.parent());
					return;
				}
				if(element.get(0).tagName.toLowerCase() == 'select') {

					element.parent().append(error)
					return;
				}
				if(type == 'checkbox') {

					var parent = element.closest('.checkbox-container');
					error.css('order', parent.css('order'));
					error.insertAfter(parent);
					return;
				}

				error.insertAfter(element);
			},
			submitHandler: function() {
				if(step1Indicator.hasClass('active')) {

					// go to step 2
					handleSuccess();
	
				} else {

					const captcha = grecaptcha.getResponse();
					if(!captcha) {

						current.find('.recaptcha-feedback').show();
						return;
					
					} else { 
					
						current.find('.recaptcha-feedback').hide();
					} 

					dynamicsErrorMessage.hide()
					cpfErrorMessage.hide()

					const calendar = current.find('[name="type"]:checked').val() == 'Presencial' ? presencialCalendar : onlineCalendar
					if(Object.keys(calendar).length === 0) {

						return false;
					}

					if(current.find('[name="agree1"]:checked').val() == false) {
						
						return;
					}

					if(current.find('[name="agree2"]:checked').val() == false) {
						
						return;
					}

					const csrfToken = Utils.getCookie('csrftoken');

					const data = { 
						unidade: form.find('[name="unit_schedule"]').val(),
					    onde_soube: form.find('[name="acknowledgment_schedule"]').val(),
					    tipo_agenda: (jQuery('[name="type"]').val() == 'Presencial') ? 'presencial': 'online',
					    agenda_id: form.find('[name="idTime"]').val(),
					    curso_id: form.find('[name="series_schedule"] option:selected').data('cursounidade'),
						escola_origem_id: form.find('[name="schoolorigin"]')?.val(),
					    segmento_id: form.find('[name="series_schedule"]').val(),
						nome_amigo: "",
						email_amigo: "",
					    recaptcha_codigo: captcha
					};

					if(form.find('[name="visitor"]:checked').val() == 'Responsável') {

						data.tipo_identificacao = 'responsavel'
						data.nome = form.find('[name="name_visitor"]').val()
					    data.cpf = form.find('[name="cpf"]').val().replace(/\D/g,'')
						data.data_nascimento = formatDateToFetch(form.find('[name="birth_date"]').val());
					    data.email = form.find('[name="email"]').val()
					    data.telefone = form.find('[name="phone"]').val()
					    data.nome_aluno = form.find('[name="name_student"]').val()

					} else {

						data.tipo_identificacao = 'aluno'
						data.nome = form.find('[name="name_visitor"]').val(),
					    data.cpf = form.find('[name="cpf"]').val().replace(/\D/g,'')
						data.data_nascimento = formatDateToFetch(form.find('[name="birth_date"]').val());
					    data.email = form.find('[name="email"]').val()
					    data.telefone = form.find('[name="phone"]').val()
						data.nome_aluno = ""

					}

					const utm_params = UTM.getLocalStorage();

					const formData = {
						...utm_params,
						...data
					}

					current.addClass('is-loading');
					fetch('/dynamics/leads/', {
						method: 'POST',
						headers: {
				      		'Accept': 'application/json',
				      		'X-CSRFToken': csrfToken,
				      		'Content-Type': 'application/json'
		    			},
						body: JSON.stringify(formData)
					}).then(function(response) {
						
						const unidadeInteresse = current.find('select[name="unit_schedule"]').find('option:selected').text();
						const segmentoInteresse = current.find('select[name="series_schedule"]').find('option:selected').text();
						const tipoUsuario = (form.find('[name="visitor"]:checked').val() == 'Responsável') ? 'Responsável' : 'Aluno';
						
						if(response.status == 200) {

							Tracking.track_submit_success('schedule', unidadeInteresse, segmentoInteresse, tipoUsuario);
							const redirect_success = form.find('[name="redirect_success"]');
							if(redirect_success.length > 0) {
							
								window.location.href = redirect_success.val();
							
							} else {

								form.remove()
								handleSuccess(current);
							}

						} else if(response.status == 409) {

							Tracking.track_submit_duplicate('schedule', unidadeInteresse, segmentoInteresse, tipoUsuario);
							const redirect_duplicate = form.find('[name="redirect_duplicate"]');
							if(redirect_duplicate.length > 0) {

								window.location.href = redirect_duplicate.val();
							
							} else {

								cpfErrorMessage.show()
								Utils.scrollToElement(cpfErrorMessage[0])
								current.removeClass('is-loading');
							}

						} else {

							Tracking.track_submit_error('schedule', unidadeInteresse, segmentoInteresse, tipoUsuario);

							const redirect_error = form.find('[name="redirect_error"]');
							if(redirect_error.length > 0) {
			
								window.location.href = redirect_error.val();
							
							} else {

								cpfErrorMessage.hide();
								dynamicsErrorMessage.show()
								Utils.scrollToElement(dynamicsErrorMessage[0])
								current.removeClass('is-loading');
							}
						}

					}).catch(function(error) {

						dynamicsErrorMessage.show()
						Utils.scrollToElement(dynamicsErrorMessage[0])
						current.removeClass('is-loading');
					})
				}

				return false;
			},
		});
	});
});