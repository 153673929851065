import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const searchUnits = jQuery('.search-units');
	searchUnits.each(function(index) {

		const current = jQuery(this);
		const input = current.find('input[name="q"]');
		let query = Utils.getURLParam('q');
		if(query) {
			query = Utils.sanitizeText(Utils.getURLParam('q'));
			input.val(query);
		}
	});
});