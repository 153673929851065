import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const mediaGallery = jQuery('.media-gallery');
	mediaGallery.each(function(index) {

		const currentSection = jQuery(this);
		const mainCarousel = currentSection.find('.carousel.main');
		const secondaryCarousel = currentSection.find('.carousel.secondary');
		
		let mainSlider = tns({
			
			container: mainCarousel[0],
			mouseDrag: false,
			controls: false,
			nav: false,
			loop: true,
			touch: true,
			items: 1.15,
			responsive: {

				941: {
					touch: false,
					items: 1,
				}
			}
		});

		let secondarySlider = tns({
			
			container: secondaryCarousel[0],
			mouseDrag: true,
			controls: false,
			nav: false,
			loop: true,
			touch: true,
			items: 3,
		});

		const btnPrevious = currentSection.find('.secondary-box .btn-previous');
		const btnNext = currentSection.find('.secondary-box .btn-next');
		const items = secondaryCarousel.find('.item img');
		if(items.length > 3) {

			btnPrevious.on('click', function() {
				secondarySlider.goTo('prev');
			});

			btnNext.on('click', function() {
				secondarySlider.goTo('next');
			});

		} else {

			btnPrevious.hide();
			btnNext.hide();
		}

		items.on('click', function() {

			const current = jQuery(this).parent();
			mainSlider.goTo(current.data('index') - 1);
		});

		function resizeCards() {

			const cards = mainCarousel.find('.tns-item');
			if(cards.length > 1) {

				cards.css('height', 'initial')

				setTimeout(function() {

					var maxHeight = 0;
					cards.each(function() {
						maxHeight = Math.max(maxHeight, jQuery(this).outerHeight())
					})

					cards.each(function() {
						jQuery(this).css('height', maxHeight+'px')
					})

				}, 100);
			}
		}

		window.addEventListener('resize', function(event) {

		    resizeCards();
		});

		resizeCards();
	});
});