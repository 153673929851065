import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	const cardTwoColumns = jQuery('.card-two-columns');
	cardTwoColumns.each(function(index) {

		const currentSection = jQuery(this);
		const carousel = currentSection.find('.carousel');
		const items = carousel.find('.item');

		var slider = tns({
			
			container: carousel[0],
			mouseDrag: true,
			controls: false,
			nav: false,
			loop: false,
			touch: true,
			items: 1.15,
			responsive: {

				941: {

					disable: true,
				}
			}
		});

		function resizeCards() {

			const cards = currentSection.find('.tns-item');
			if(cards.length > 1) {

				cards.css('height', 'initial')

				setTimeout(function() {

					var maxHeight = 0;
					cards.each(function() {
						maxHeight = Math.max(maxHeight, jQuery(this).outerHeight())
					})

					cards.each(function() {
						jQuery(this).css('height', maxHeight+'px')
					})

				}, 100);
			}
		}

		window.addEventListener('resize', function(event) {

		    resizeCards();
		});

		resizeCards();
	});
});