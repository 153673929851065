import Utils from '../utils.js';

document.addEventListener('sebDocumentReady', function() {

	jQuery('footer').each(function() {

		const current = jQuery(this)

		const btnScrollTop = current.find('.scroll-top button')
		btnScrollTop.on('click', function() {

			Utils.scrollToStart();
		});

		const links = current.find('.links')
		const column1 = links.find('.column-1')
		const allLinks = column1.find('a');
		const column2 = links.find('.column-2')
		const column3 = links.find('.column-3')
		const isInstitucional = links.hasClass('is-institutional');
		const divisor = isInstitucional ? 3 : 2;

		const numberPerColumn = Math.floor(allLinks.length / divisor);
		let sizeColumn1 = numberPerColumn;
		sizeColumn1 = (allLinks.length % divisor > 0) ? sizeColumn1 + 1 : sizeColumn1;
		let sizeColumn2 = numberPerColumn;
		sizeColumn2 = (allLinks.length % divisor > 1) ? sizeColumn2 + 1 : sizeColumn2;	
		let sizeColumn3 = numberPerColumn;

		allLinks.each(function(index) {

			if(index >= sizeColumn1 && index < sizeColumn1 + sizeColumn2) {

				column2.append(this)

			} else if(index >= sizeColumn1 + sizeColumn2) {

				column3.append(this)
			}
		})

		column1.show()
		column2.show()
		if(isInstitucional) {

			column3.show()
		}
	})
});