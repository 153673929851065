import 'vanilla-javascript';

import dayjs from 'dayjs'
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

require('jquery-ui/themes/base/all.css');
import 'jquery-ui/ui/widgets/selectmenu';
import 'jquery-ui/ui/widgets/datepicker';

import LazyLoad from "./js/lazyload.min.js";
import Rellax from 'rellax';
import AOS from 'aos';
import UTM from './js/utm.js';
import './js/mask.js';
import '../node_modules/aos/src/sass/aos.scss';
import '../node_modules/tiny-slider/src/tiny-slider.scss';
import './js/pagination.js';

import './js/modal-media.js';
//import './js/posts.js';
// import './js/cookies.js';
import './js/selects.js';

import './js/select2.min.js';
import './styles/select2.min.css';

import './js/plugins/header.js';
import './js/plugins/footer.js';
import './js/plugins/testimony';
import './js/plugins/carousel-one-column.js';
import './js/plugins/carousel-three-columns.js';
import './js/plugins/dropdown.js';
import './js/plugins/segments.js';
import './js/plugins/results-units.js';
import './js/plugins/search-units.js';
//import './js/plugins/blog.js';
import './js/plugins/blog2.js';
import './js/plugins/card-two-columns.js';
import './js/plugins/media-gallery.js';
import './js/plugins/map.js';
import './js/plugins/square-image-video.js';
import './js/plugins/animated-homepage.js';
import './js/plugins/contact-schedule.js';
import './js/plugins/contact-form.js';
import './js/plugins/contact-form-schedule.js';
import './js/plugins/schedule-seb.js';
import './js/plugins/forms-contact.js';
import './js/plugins/animated-numbers';
import './js/plugins/schedule-calendar.js';

import "./styles/main.scss";

const lazyLoadInstance = new LazyLoad({
})

var rellax = undefined;
if(jQuery('.rellax').length > 0) {
	rellax = new Rellax('.rellax')
}


AOS.init();
UTM.saveFromURL();

document.addEventListener('sebDocumentReady', function() {

	lazyLoadInstance.update()
	if(rellax) {
		rellax.refresh()
	}
	AOS.init();
})

document.addEventListener('refreshLazyLoad', function() {
	
	lazyLoadInstance.update()
	if(rellax) {
		rellax.refresh()
	}
});

if(jQuery.validator) {

	jQuery.extend( jQuery.validator.messages, {
		// Core
		required: "Este campo &eacute; requerido.",
		remote: "Por favor, corrija este campo.",
		email: "Por favor, forne&ccedil;a um endere&ccedil;o de email v&aacute;lido.",
		url: "Por favor, forne&ccedil;a uma URL v&aacute;lida.",
		date: "Por favor, forne&ccedil;a uma data v&aacute;lida.",
		dateISO: "Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).",
		number: "Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.",
		digits: "Por favor, forne&ccedil;a somente d&iacute;gitos.",
		creditcard: "Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.",
		equalTo: "Por favor, forne&ccedil;a o mesmo valor novamente.",
		maxlength: $.validator.format( "Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres." ),
		minlength: $.validator.format( "Por favor, forne&ccedil;a ao menos {0} caracteres." ),
		rangelength: $.validator.format( "Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento." ),
		range: $.validator.format( "Por favor, forne&ccedil;a um valor entre {0} e {1}." ),
		max: $.validator.format( "Por favor, forne&ccedil;a um valor menor ou igual a {0}." ),
		min: $.validator.format( "Por favor, forne&ccedil;a um valor maior ou igual a {0}." ),
		step: $.validator.format( "Por favor, forne&ccedil;a um valor m&uacute;ltiplo de {0}." ),

		// Metodos Adicionais
		maxWords: $.validator.format( "Por favor, forne&ccedil;a com {0} palavras ou menos." ),
		minWords: $.validator.format( "Por favor, forne&ccedil;a pelo menos {0} palavras." ),
		rangeWords: $.validator.format( "Por favor, forne&ccedil;a entre {0} e {1} palavras." ),
		accept: "Por favor, forne&ccedil;a um tipo v&aacute;lido.",
		alphanumeric: "Por favor, forne&ccedil;a somente com letras, n&uacute;meros e sublinhados.",
		bankaccountNL: "Por favor, forne&ccedil;a com um n&uacute;mero de conta banc&aacute;ria v&aacute;lida.",
		bankorgiroaccountNL: "Por favor, forne&ccedil;a um banco v&aacute;lido ou n&uacute;mero de conta.",
		bic: "Por favor, forne&ccedil;a um c&oacute;digo BIC v&aacute;lido.",
		cifES: "Por favor, forne&ccedil;a um c&oacute;digo CIF v&aacute;lido.",
		creditcardtypes: "Por favor, forne&ccedil;a um n&uacute;mero de cart&atilde;o de cr&eacute;dito v&aacute;lido.",
		currency: "Por favor, forne&ccedil;a uma moeda v&aacute;lida.",
		dateFA: "Por favor, forne&ccedil;a uma data correta.",
		dateITA: "Por favor, forne&ccedil;a uma data correta.",
		dateNL: "Por favor, forne&ccedil;a uma data correta.",
		extension: "Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.",
		giroaccountNL: "Por favor, forne&ccedil;a um n&uacute;mero de conta corrente v&aacute;lido.",
		iban: "Por favor, forne&ccedil;a um c&oacute;digo IBAN v&aacute;lido.",
		integer: "Por favor, forne&ccedil;a um n&uacute;mero n&atilde;o decimal.",
		ipv4: "Por favor, forne&ccedil;a um IPv4 v&aacute;lido.",
		ipv6: "Por favor, forne&ccedil;a um IPv6 v&aacute;lido.",
		lettersonly: "Por favor, forne&ccedil;a apenas com letras.",
		letterswithbasicpunc: "Por favor, forne&ccedil;a apenas letras ou pontua&ccedil;ões.",
		mobileNL: "Por favor, fornece&ccedil;a um n&uacute;mero v&aacute;lido de telefone.",
		mobileUK: "Por favor, fornece&ccedil;a um n&uacute;mero v&aacute;lido de telefone.",
		nieES: "Por favor, forne&ccedil;a um NIE v&aacute;lido.",
		nifES: "Por favor, forne&ccedil;a um NIF v&aacute;lido.",
		nowhitespace: "Por favor, n&atilde;o utilize espa&ccedil;os em branco.",
		pattern: "O formato fornecido &eacute; inv&aacute;lido.",
		phoneNL: "Por favor, forne&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
		phoneUK: "Por favor, forne&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
		phoneUS: "Por favor, forne&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
		phonesUK: "Por favor, forne&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
		postalCodeCA: "Por favor, forne&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
		postalcodeIT: "Por favor, forne&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
		postalcodeNL: "Por favor, forne&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
		postcodeUK: "Por favor, forne&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
		postalcodeBR: "Por favor, forne&ccedil;a um CEP v&aacute;lido.",
		require_from_group: $.validator.format( "Por favor, forne&ccedil;a pelo menos {0} destes campos." ),
		skip_or_fill_minimum: $.validator.format( "Por favor, optar entre ignorar esses campos ou preencher pelo menos {0} deles." ),
		stateUS: "Por favor, forne&ccedil;a um estado v&aacute;lido.",
		strippedminlength: $.validator.format( "Por favor, forne&ccedil;a pelo menos {0} caracteres." ),
		time: "Por favor, forne&ccedil;a um hor&aacute;rio v&aacute;lido, no intervado de 00:00 a 23:59.",
		time12h: "Por favor, forne&ccedil;a um hor&aacute;rio v&aacute;lido, no intervado de 01:00 a 12:59 am/pm.",
		url2: "Por favor, forne&ccedil;a uma URL v&aacute;lida.",
		vinUS: "O n&uacute;mero de identifica&ccedil;&atilde;o de ve&iacute;culo informado (VIN) &eacute; inv&aacute;lido.",
		zipcodeUS: "Por favor, forne&ccedil;a um c&oacute;digo postal americano v&aacute;lido.",
		ziprange: "O c&oacute;digo postal deve estar entre 902xx-xxxx e 905xx-xxxx",
		cpfBR: "Por favor, forne&ccedil;a um CPF v&aacute;lido.",
		nisBR: "Por favor, forne&ccedil;a um NIS/PIS v&aacute;lido",
		cnhBR: "Por favor, forne&ccedil;a um CNH v&aacute;lido.",
		cnpjBR: "Por favor, forne&ccedil;a um CNPJ v&aacute;lido."
	} );

	jQuery.validator.addMethod("validDate", function(value, element) {

		const day = dayjs(value, 'DD/MM/YYYY', 'pt', true)
		return day.isValid() && day.isBefore(dayjs())
	}, "Forneça uma data válida");
}
const html = document.querySelectorAll('html')[0]
jQuery.validator.addMethod("cpf", function(value, element) {

	if (!value) return false

	  const formatToString = val => {
	    if (Array.isArray(val)) return val.join('')
	    if (typeof val === 'string') return val
	    return null
	  }

	  const cpfString = formatToString(value)

	  if (!cpfString) return false

	  let cpf = cpfString.replace(/[^\d]+/g, '')

	  // Validates length
	  if (cpf.length !== 11) return false

	  // Clear invalids
	  if (/^(\d)\1+$/.test(cpf)) return false

	  let sum = 0
	  let remain

	  for (let i = 1; i <= 9; i++)
	    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
	  remain = (sum * 10) % 11

	  if (remain == 10 || remain == 11) remain = 0
	  if (remain != parseInt(cpf.substring(9, 10))) return false

	  sum = 0
	  for (let i = 1; i <= 10; i++)
	    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
	  remain = (sum * 10) % 11

	  if (remain == 10 || remain == 11) remain = 0
	  if (remain != parseInt(cpf.substring(10, 11))) return false

	  return true

}, (html.getAttribute('lang') == 'pt') ? 'Informe um CPF válido' : 'Enter a valid CPF');

jQuery.validator.addMethod("customEmail", function(value, element) {
	return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
}, "Por favor, forne&ccedil;a um endere&ccedil;o de email v&aacute;lido.");

function scrollMenuAnchor() { 
	let menuLinks = document.querySelectorAll(".menu-links a[href^='#']");

  	menuLinks.forEach(function (link) {
    	link.addEventListener("click", function (e) {
     		e.preventDefault();
      		let targetId = this.getAttribute("href");
      		let targetElement = document.getElementById(targetId);
      		let targetTop = targetElement.offsetTop;
      		window.scrollTo({
        		top: targetTop,
        		behavior: "smooth",
      		});
    	});
  	});
}

scrollMenuAnchor();

